import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["burgerButton", "navItem"];

  connect() {
    this.setNavItemTabIndex();
    window.addEventListener("resize", this.setNavItemTabIndex.bind(this));
    // remove the proload class to allow transitions after pageload
    this.element.classList.remove("header-navigation--preload");
  }

  setNavItemTabIndex() {
    const mobile = window.innerWidth < 720;
    this.navItemTargets.forEach((navItem) => {
      navItem.setAttribute("tabindex", mobile ? -1 : 0);
    });
  }

  toggleMenu() {
    this.element.classList.toggle("main-navigation--opened");
    this.burgerButtonTarget.toggleAttribute("aria-expanded");
    this.burgerButtonTarget.classList.toggle("change");
    this.navItemTargets.forEach((navItem) => {
      const tabIndex = Number(navItem.getAttribute("tabindex"));
      navItem.setAttribute("tabindex", tabIndex === -1 ? 0 : -1);
    });
  }
}
