import { Controller } from "stimulus"
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

export default class extends Controller {
  initialize() {
    // let imagesElement = document.getElementById('images');
    let csrfTokenElement = document.querySelector('meta[name="csrf-token');
    console.log(csrfTokenElement);
    let mainSliderImagesDropzoneElement = document.getElementById("dropzone-main-slider-images");
    console.log(mainSliderImagesDropzoneElement);
    let mainSliderImagesDropzone = new Dropzone("div#dropzone-main-slider-images", {
      url: mainSliderImagesDropzoneElement.dataset.url,
      headers: {
        'X-CSRF-Token': csrfTokenElement.getAttribute('content')
      }
    });
    mainSliderImagesDropzone.on("success", (file, response) => {
      console.log(`File added: ${file.name}`);
      const img = document.createElement("img");
      img.setAttribute('src', response.file);

      // imagesElement.appendChild(img);
    });
  }
}
